body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.headerContainer {
  background-color: #00a651;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}

.headerContainer  img{
 height: 60px;
}

.footerContainer {
  background-color: #00a651;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.dataContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  
  align-items: center;
}

.district-list {
  overflow-y: scroll;
  max-height: 100px;
}

.district {
  margin-left: 30px;
}

form {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 250px;
}

form label {
  margin-bottom: 20px;
}

select {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 8px 0;
  padding: 12px 20px;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}